<template>
    <v-row justify="center" align="center" no-gutters>
        <v-col cols="12" xl="6" lg="6" md="8" sm="8">

            <v-row justify="center" no-gutters>
                <span v-if="manualTextInputSelected" class="text-h6 text-sm-h5 text-center">Text Input
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-icon small v-on="on" class="text-decoration-none">mdi-information-outline</v-icon>
                        </template>
                        <span>This option allows you to input words, or sentences,<br/>one by one to create your post.<br/></span>
                    </v-tooltip>
                </span>
                <span v-else-if="textFileUploadSelected" class="text-h6 text-sm-h5 text-center">Select a Text File to Upload
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-icon small v-on="on" class="text-decoration-none">mdi-information-outline</v-icon>
                        </template>
                        <span>
                            This option allows you to upload a text file and separate it's contents based on the option below.<br/>
                            <!-- <br/> -->
                            <!-- <br/> -->
                            <!-- <br/> -->
                            <!-- <br/> -->
                            <!-- <br/> -->
                        </span>
                    </v-tooltip>
                </span>
                <span v-else class="text-h6 text-sm-h5 text-center">Select <span class="font-weight-black text-decoration-underline">Text</span> to input your own, or <span class="font-weight-black text-decoration-underline">File</span> to upload from your device</span>
            </v-row>

            <v-row justify="center" align="center" no-gutters class="mt-8">
                <v-hover v-slot="{hover}">
                    <div class="d-flex flex-column justify-end align-center cursor--pointer" @click="[textFileUploadSelected = false, manualTextInputSelected = true]">
                        <v-icon size="100" color="warning" :class="[ hover ? 'text--darken-1' : manualTextInputSelected ? 'text--darken-0' : textFileUploadSelected ? 'opacity--low' : 'warning--text']">mdi-format-text</v-icon>
                        <div class="text-subtitle-1 text-center">Text</div>
                    </div>
                </v-hover>

                <v-hover v-slot="{hover}">
                    <div class="d-flex flex-column justify-end align-center ml-8 cursor--pointer" @click="textFileUploadSelected = true, manualTextInputSelected = false">
                        <v-icon size="100" class="primary--text" :class="[ hover ? 'text--lighten-1' : textFileUploadSelected ? 'text--lighten-0' : manualTextInputSelected ? 'opacity--low' : 'primary--text']">mdi-file-upload</v-icon>
                        <div class="text-subtitle-1 text-center">File</div>
                    </div>
                </v-hover>
            </v-row>

            <v-form>
                <!-- v-model="isFormValid" -->
                <v-row v-if="textFileUploadSelected || manualTextInputSelected" justify="center" no-gutters class="mt-4">
                    <transition enter-active-class="slideDown" leave-active-class="slideUp" appear>
                        <v-col cols="12" xl="8" lg="8" md="10" sm="10">

                            <!-- We shouldn't need the below code because we will just extract the text from the file and add it to 'textAdded' value and save it to our store. -->
                            <!-- For drafts we will add the text to the database and save it -->
                            <!-- <div v-if="userNeedsToReselectVideo" class="text-subtitle-2 accent--text mb-4">*Select '{{draftPost.draftVideo.name}}' for optimal results.</div> -->

                            <div v-if="showAlertText" class="text-subtitle-2 accent--text mb-4">{{alertText}}</div>

                            <transition enter-active-class="slideRight">
                                <div>
                                    <div v-if="textFileUploadSelected">
                                        <v-file-input
                                            v-show="!manualTextInputSelected"
                                            v-model="textPost.file"
                                            :rules="fileRules"
                                            :show-size="true"
                                            id="input"
                                            accept=".txt, .csv"
                                            flat
                                            outlined
                                            clearable
                                            prepend-icon=""
                                            prepend-inner-icon="$file"
                                            required
                                            label="Upload a Text File"
                                            persistent-hint
                                            :hint="textFileHint"
                                            :loading="checkingFileType"
                                            :error="textFileError"
                                            @change="fileChanged()"
                                        ></v-file-input>
                                        <!-- accept=".txt, .csv, .json, .xml, .html, .md, text/*" -->
                                        <!-- accept=".txt, .csv, .json, .xml, .html, .md, .doc, .docx, .pdf, text/*" -->

                                        <v-radio-group v-model="textPost.fileTextSeparationType" row persistent-hint label="Select Text Separation Type:" :disabled="isCSV || textFileError || textFileNotSelected || parsingTextFile" :hint="fileTextSeparationHint" class="mt-0 mb-5" @change="fileTextSeparationTypeChanged">
                                            <v-radio label="Space" value=" (Space)"/>
                                            <v-radio label="Comma (,)" value=", (Comma)"/>
                                            <v-radio label="Semicolon (;)" value="; (Semicolon)"/>
                                            <v-radio label="Forward Slash (/)" value="/ (Forward Slash)"/>
                                            <v-radio label="New Line" value="\n (New Line Character)"/>
                                            <v-radio label="Period (.)" value=". (Period)"/>
                                        </v-radio-group>

                                        <!-- <span>Added Text: {{ selectedTextChip }}</span><br/> -->
                                        <span>Added Text:</span><br/>
                                        <v-divider class="mb-3"/>
                                        <div class="text-added-container">
                                            <v-row v-if="textPost.textAdded.length >= 1" class="ma-0 pa-0">
                                                <v-col cols="12">
                                                    <!-- <v-chip close pill v-for="(text, index) in textPost.textAdded" :key="index" color="primary" class="mx-1 my-1" @click:close="removeTextChip(text)">{{ text }}</v-chip> -->
                                                    <v-chip close pill v-for="(text, index) in textPost.textAdded" :key="index" color="primary" class="mx-1 my-1" @click="openTextChip(text)" @click:close="removeTextChip(text)">{{ getTrucatedText(text) }}</v-chip>
                                                </v-col>
                                            </v-row>
                                            <p v-else class="primary--text text-center">No text has been added yet! <br/> Upload a file and select a separation type above.</p>
                                        </div>
                                        <v-divider class="my-3"/>
                                    </div>

                                    <div  v-if="manualTextInputSelected">
                                        <v-text-field
                                            v-show="!textFileUploadSelected"
                                            v-model="text"
                                            clearable
                                            flat
                                            outlined
                                            counter="150"
                                            label="Enter your Text here"
                                            append-outer-icon="mdi-pencil-plus-outline"
                                            :error="textInputError"
                                            :error-messages="errorMessage"
                                            :rules="textAddedRules"
                                            @input="handleInput"
                                            @click:append-outer="addTextChip"
                                            @keypress.enter="addTextChip"
                                        ></v-text-field>

                                        <span>Added Text:</span><br/>
                                        <v-divider class="mb-3"/>
                                        <div class="text-added-container">
                                            <v-row v-if="textPost.textAdded.length >= 1" class="ma-0 pa-0">
                                                <v-col cols="12">
                                                    <!-- <v-chip close pill v-for="(text, index) in textPost.textAdded" :key="index" color="primary" @click:close="removeTextChip(text)" class="mx-1 my-1">{{ text }}</v-chip> -->
                                                    <v-chip close pill v-for="(text, index) in textPost.textAdded" :key="index" color="primary" class="mx-1 my-1" @click="openTextChip(text)" @click:close="removeTextChip(text)">{{ getTrucatedText(text) }}</v-chip>
                                                </v-col>
                                            </v-row>
                                            <p v-else class="primary--text text-center">No text has been added yet!</p>
                                        </div>
                                        <v-divider class="mb-3"/>

                                        <!-- <div v-if="suggestedText.length >= 1" class="mb-5">
                                            <span>Suggested Text:</span><br/>
                                            <v-divider class="mb-3"/>
                                            <v-row class="ma-0 pa-0">
                                                <v-col cols="12">
                                                    <v-chip close close-icon="mdi-plus"  v-for="(t, i) in suggestedText" :key="i" color="primary"  @click:close="addSuggestedChip(t,i)" class="mx-1">{{ t }}</v-chip>
                                                </v-col>
                                            </v-row>
                                            <v-divider class="my-3"/> 
                                        </div> -->
                                    </div>
                                </div>
                            </transition>

                            <v-autocomplete
                                v-model="languageSelected"
                                :label="!hasAudio && !hasCaptions ? 'Select a language (required)' : 'Delete your tawq segments to change language.'"
                                :items="languageCodes"
                                item-text="text"
                                item-value="value"
                                return-object
                                outlined
                                persistent-hint
                                :hint="languageSelected.value === 'null' ? '' : '*This will be the langauge you speak while recording'"
                                :disabled="hasAudio || hasCaptions"
                            ></v-autocomplete>

                            <v-text-field
                                v-if="languageSelected.value === 'null'"
                                v-model="noCaptionLanguageSelected"
                                outlined
                                :label="!hasAudio && !hasCaptions ? 'Type in the language you will speak' : 'Delete your tawq segments to change language.'"
                                :disabled="hasAudio || hasCaptions"
                            ></v-text-field>

                        </v-col>
                    </transition>
                </v-row>
            </v-form>

        </v-col> 
    </v-row>
</template>
<script>


// import axios from "axios";
// const fs = require("fs");
// import fs from "fs";

export default {
    name: 'TextContentMediaSelection',
    props: [ "isDraftPost", "draftPost", "languageCodes",],
    // components: {},
    data(){
        return{
            isFormValid: false,
            textFileUploadSelected: false,
            textFile: {},
            uploadedTextFileContents: null,
            textFileExtension: "",
            textFileError: false,
            textFileHint: "Allowed file types: .txt, .csv",
            // textFileHint: "Allowed file types: .txt, .csv, .json, .xml, .html, .md",
            // allowedTextFileTypes: [ "txt", "csv", "json", "xml", "html", "md"],
            textFileIsValid: false,
            checkingFileType: false,
            isCSV: false,
            textFileNotSelected: true,
            fileTextSeparationHint: "*This determines how the text in your file is separated.", // If none is selected, each word will be separated by Space. CSV files are Comma separated by default
            parsingTextFile: false,
            selectedTextChip: "",

            manualTextInputSelected: false,
            text: "",
            // suggestedText: ["tawq.in", "translation", "community",],
            // suggestedText: [], // right now these are hard coded to come from this array, but can easily be updated to pull from somewhere else, or even the database.
            textInputError: false,
            userHasStartedTyping: false,
            errorMessage: "",

            // RULES
            fileRules: [
                (v) => !!v || "Must select a file. Allowed file types: .txt, .csv",
                // (v) => !!v || "Must select a file. Allowed file types: .txt, .csv, .json, .xml, .html, .md",
            ],
            textAddedRules: [
                (v) => (!this.userHasStartedTyping || !!v) || "You must enter Text",
                (v) => (v.length <= 150) || "Text must be 150 characters or less",
            ],
            // END RULES

            alertText: "",
            showAlertText: false,
            // hasBeenValidated: false,
        }
    },
    methods: {
        fileChanged: async function () {
            // console.log("file changed!");
            this.checkingFileType = true;
            const validHint = "Allowed file types: .txt, .csv";
            // const validHint = "Allowed file types: .txt, .csv, .json, .xml, .html, .md";
            const invalidHint = "This file is not valid. Please select a valid file. " + validHint;

            if (this.isCSV) {this.isCSV = false; this.textPost.fileTextSeparationType = null;}
            if (this.textPost.fileTextSeparationType != null) {this.parsingTextFile = false; this.textPost.fileTextSeparationType = null;}
            if (!this.textPost.file) {
                this.textFileNotSelected = true;
                this.textFileError = false;
                this.textFileHint = validHint;
                this.checkingFileType = false;
                if (this.textPost.textAdded.length >= 1) this.clearTextChips();
                return;
            }

            this.uploadedTextFileContents = await this.readFile(this.textPost.file);
            // console.log("uploadedTextFileContents: ", this.uploadedTextFileContents);
            const isFileEmpty = !/\S/.test(this.uploadedTextFileContents);

            if (isFileEmpty) {
                this.textFileError = true;
                this.textFileHint = invalidHint;
            } else {
                this.textFileNotSelected = false;
                this.textFileExtension = this.textPost.file.name.split('.').pop();
                this.textFileError = false;
                this.textFileHint = validHint;
                if (this.textFileExtension === "csv") {
                    this.isCSV = true;
                    this.parseCSVFile();
                }
            }

            this.checkingFileType = false;
        },
        readFile: function () {
            return new Promise((resolve, reject) => {
                let reader = new FileReader(); // Create file reader

                reader.addEventListener("loadend", e => resolve(e.target.result)); // Register event listeners
                reader.addEventListener("error", reject); // I don't think I need to turn off these event listeners because only the file reader has access to them!

                reader.readAsText(this.textPost.file); // Read file
            })
        },
        fileTextSeparationTypeChanged: function () {
            console.log("fileTextSeparationTypeChanged!");
            this.fileTextSeparationType = this.textPost.fileTextSeparationType;
            console.log("fileTextSeparationType: ", this.fileTextSeparationType);
            this.parsingTextFile = true;
            this.parseUploadedFile();
        },
        parseUploadedFile: function () {
            // console.log("parseUploadedFile");
            switch (this.textFileExtension) {
                case "txt":
                    console.log("txt file");
                    this.separateFileByTextSeparationType();
                    break;
                case "csv":
                    console.log("csv file");
                    this.parseCSVFile();
                    // this.textPost.fileTextSeparationType = ", (Comma)";

                    // const lines = this.uploadedTextFileContents.split('\n'); // Split the CSV content into lines

                    // lines.shift(); // Strip the first line (usually headers in CSVs)

                    // lines.forEach(line => { // Iterate over the remaining lines, separate by comma and add text to the list
                    //     const items = line.split(',');

                    //     items.forEach(item => {
                    //         const cleanedText = item.trim(); // Strip any whitespace
                    //         if (cleanedText) this.textPost.textAdded.push(cleanedText); // Add cleaned text to textAdded list
                    //     });
                    // });
                    break;
                case "json":
                    console.log("json file");
                    this.parseJSONFile();
                    break;
                case "xml":
                    console.log("xml file");
                    this.parseXMLFile();
                    break;
                case "html":
                    console.log("html file");
                    this.parseHTMLFile();
                    break;
                case "md":
                    console.log("md file");
                    this.separateFileByTextSeparationType();
                    break;
                default: // all other cases of text files
                    console.log("default file type, none of the others. ", this.textFileExtension);
                    break;
            }
            this.parsingTextFile = true;


            // return new Promise(async (resolve, reject) => {
            //     resolve(true);
            // });
        },
        separateFileByTextSeparationType: function () {
            // console.log("separateFileByTextSeparationType: ", this.fileTextSeparationType);
            // console.log("uploadedTextFileContents: ", this.uploadedTextFileContents);

            // 6 different values to separate on - space (' '), comma (','), semicolon (';'), forward slash ('/'), new line ('/n'), period ('.')
            let delimiter = this.fileTextSeparationType.split(' ')[0]; // Gets separation type and removes space, parentheses and everything after
            // console.log("delimiter: ", delimiter);
            let separatedText;

            if (delimiter === "," || delimiter === ";" || delimiter === "/" || delimiter === ".") { // separate by comma, semicolon, slash, period
                // console.log("separate by all others");
                separatedText = this.uploadedTextFileContents.split(delimiter); // Comma, semicolon, slash, period
            } else if (delimiter === "") { // separate by space
                // console.log("separate by space");
                separatedText = this.uploadedTextFileContents.split(/\s+/); // Space
            } else { // separate by new line character
                // console.log("separate by new line");
                separatedText = this.uploadedTextFileContents.split(/\r?\n|\r/); // New line
            }
            // console.log("separatedText: ", separatedText);

            // Add the separated text to a list called textAdded
            this.textPost.textAdded = separatedText.map(text => text.trim()).filter(text => text !== ""); // Clean up any extra spaces
            if (this.textPost.textAdded.length > 0) { this.mediaSelectionStep.complete = true; }
            // this.mediaSelectionStep.complete = true;
            console.log("textPost.textAdded: ", this.textPost.textAdded.length);


            // switch (this.fileTextSeparationType) {
            //     case " (Space)":
            //         console.log(" (Space)");
            //         break;
            //     case ", (Comma)":
            //         console.log(", (Comma)");
            //         break;
            //     case ": (Semicolon)":
            //         console.log(": (Semicolon)");
            //         break;
            //     case "/ (Forward Slash)":
            //         console.log("/ (Forward Slash)");
            //         break;
            //     case "\n (New Line Character)":
            //         console.log("\n (New Line Character)");
            //         break;
            //     case ". (Period)":
            //         console.log(". (Period)");
            //         break;
            // };
        },
        parseCSVFile: function () {
            console.log("parse csv file");
            this.textPost.fileTextSeparationType = ", (Comma)";

            const lines = this.uploadedTextFileContents.split('\n'); // Split the CSV content into lines

            lines.shift(); // Strip the first line (usually headers in CSVs)

            lines.forEach(line => { // Iterate over the remaining lines, separate by comma and add text to the list
                const items = line.split(',');

                items.forEach(item => {
                    const cleanedText = item.trim(); // Strip any whitespace
                    if (cleanedText) this.textPost.textAdded.push(cleanedText); // Add cleaned text to textAdded list
                });
            });
            if (this.textPost.textAdded.length > 0) { this.mediaSelectionStep.complete = true; }
        },
        parseTXTFile: function () {
            console.log("txt file");
        },
        parseJSONFile: function () {
            console.log("json file");
        },
        parseXMLFile: function () {
            console.log("xml file");
        },
        parseHTMLFile: function () {
            console.log("html file");
        },
        parseMDFile: function () {
            console.log("md file");
        },
        handleInput: function () {
            // console.log("handleInput called!");
            this.userHasStartedTyping = true; // Set the flag when the user starts typing

            // If the user clears the input after typing, set the error state
            if (this.text === "") {
                this.textInputError = true;
            } else {
                this.textInputError = false; // No error if there's text in the input
                this.errorMessage = "";
            }
        },
        updateScroll: function() { // Caption has been converted and pushed to top of list, scroll to the top of the list.
            var element = document.getElementsByClassName("text-added-container")[0];
            let height = element.scrollHeight;
            element.scrollTop = ( 0 - height );
        },
        getTrucatedText: function (text) {
            var element = document.getElementsByClassName("text-added-container")[0];
            let width = (element == undefined) ? 150 : element.scrollWidth;
            // console.log("width: ", width);

            let divideBy = 8; // can be updated to be more dynamic;
            let screenWidth = width / divideBy;
            // console.log("Screen width: " + screenWidth);

            const maxLength = Math.round(screenWidth);
            if (text.length > maxLength) {
                return text.substring(0, maxLength) + "...";
            } else {
                return text;
            }
        },
        addTextChip: function () {
            // console.log("addTextChip called");
            // ADD A CHECK TO MAKE SURE TEXT BEING ADDED ISN'T ALREADY IN THE LIST!!!
            if (this.text != "" && this.text.length <= 150) {
                this.text = this.text.trim();

                this.textPost.textAdded.unshift(this.text);
                if (this.textPost.textAdded > 1) this.updateScroll();

                this.text = "";
                this.textInputError = false;
                this.userHasStartedTyping = false;
                this.mediaSelectionStep.complete = true;
            } else {
                // console.log("set error message");
                this.errorMessage = "You must enter Text"
                this.userHasStartedTyping = true;
                this.textInputError = true;
            }
        },
        // addSuggestedChip: function (text, index) {
        //     if (!this.textPost.textAdded.includes(text)) {
        //         this.textPost.textAdded.push(text);
        //         this.suggestedText.splice(index, 1);
        //     } else if (this.textPost.textAdded.includes(text)) {
        //         this.suggestedText.splice(index, 1);
        //     } // else do nothing
        // },
        openTextChip: function (text) {
            this.selectedTextChip = text;
        },
        removeTextChip: function (text) {
            this.textPost.textAdded.splice(this.textPost.textAdded.indexOf(text), 1);
            if (this.textPost.textAdded.length == 0) {
                this.mediaSelectionStep.complete = false;
            }
        },
        clearTextChips: function () {
            this.textPost.textAdded = [];
            this.mediaSelectionStep.complete = false;
        },
    },
    watch:{
        // isFormValid (newValue) {
        //     console.log("isFormValid: ", newValue);
        //     if (newValue) {
        //         this.$store.commit('newTextPost/saveMediaSelectionStep', { complete: false, textFileUploadSelected: this.textFileUploadSelected, manualTextInputSelected: this.manualTextInputSelected });
        //     } else {
        //         this.$store.commit('newTextPost/saveMediaSelectionStep', { complete: false, textFileUploadSelected: this.textFileUploadSelected, manualTextInputSelected: this.manualTextInputSelected });
        //     }
        // },
        manualTextInputSelected (newValue) {
            // console.log("manualTextInputSelected: ", newValue);
            this.$store.commit('newTextPost/saveMediaSelectionStep', { manualTextInputSelected: newValue });
            // this.$store.commit('newTextPost/saveMediaSelectionType', { type: newValue });
        },
        textFileUploadSelected (newValue) {
            // console.log("textFileUploadSelected: ", newValue);
            this.$store.commit('newTextPost/saveMediaSelectionStep', { textFileUploadSelected: newValue });
            // this.$store.commit('newTextPost/saveMediaSelectionType', { type: newValue });
        }
    },
    computed:{
        isMobile () {
            return this.$store.state.isMobileDevice;
        },
        languageSelected: {
            get(){
                return this.$store.state.newTextPost.textPost.languageSelected;
            },
            set(value){
                if (value != undefined) {
                    this.$store.commit('newTextPost/saveTextPostLanguageSelected', value);
                }
            }
        },
        noCaptionLanguageSelected: {
            get(){
                return this.$store.state.newTextPost.textPost.noCaptionLanguageSelected;
            },
            set(value){
                // if (value != undefined) { 
                     this.$store.commit('newTextPost/saveTextPostNoCaptionLanguageSelected', value);
                // }
            }
        },
        tawqData: {
            get(){
                return this.$store.state.newTextPost.tawqData;
            },
            set(value){
                this.$store.commit('newTextPost/saveTawqData', value);
            }
        },
        hasCaptions () {
            return Object.values(this.tawqData).some((item) => item.caption !== undefined && item.caption !== null);
        },
        hasAudio () {
            return Object.values(this.tawqData).some((item) => item.audio !== undefined && item.audio !== null);
        },
        textPost: {
            get(){
                return this.$store.state.newTextPost.textPost;
            },
            set(value){
                this.$store.commit('newTextPost/saveTextPost', value);
            }
        },
        mediaSelectionStep: {
            // return this.$store.state.newTextPost.mediaSelectionStep;
            get(){
                return this.$store.state.newTextPost.mediaSelectionStep;
            },
            set(value){
                this.$store.commit('newTextPost/mediaSelectionStep', value);
            }
        }
    },
    beforeDestroy () {
        this.$emit('setMediaSelectionData', { textFileUploadSelected: this.textFileUploadSelected, manualTextInputSelected: this.manualTextInputSelected } );
    },
    created () {
        // if (this.mediaSelectionStep.complete) {
        //     // console.log("in created it seems that media seleciton step is complete...");
        //     this.manualTextInputSelected = this.mediaSelectionStep.manualTextInputSelected;
        //     this.textFileUploadSelected = this.mediaSelectionStep.textFileUploadSelected;
        //     console.log("textFileUploadSelected: ", this.textFileUploadSelected);
        //     if (this.isDraftPost) {
        //         this.isFormValid = true; // TODO: PROB DON'T NEED THIS
        //         // this.videoUrl = typeof this.draftPost.draftVideo.url === 'undefined' ? "" : this.draftPost.draftVideo.url;
        //     }
        // }

        // this.textPost.fileTextSeparationType = null;

        if (this.isDraftPost && (this.draftPost.draftVideo.type === 'video/mp4' || this.draftPost.draftVideo.type.split('/')[0] === 'audio') && !this.textPost.transcoded) {
            if (this.textPost.video) {
                if (this.textPost.video.name === this.draftPost.draftVideo.name) {
                    this.userNeedsToReselectVideo = false;
                } else {
                    this.userNeedsToReselectVideo = true;
                }
            } else {
                this.userNeedsToReselectVideo = true;
            }
            this.manualTextInputSelected = false;
            this.textFileUploadSelected = true;
        } else {
            // console.log("this.mediaSelectionStep: ", this.mediaSelectionStep);
            this.manualTextInputSelected = this.mediaSelectionStep.manualTextInputSelected;
            this.textFileUploadSelected = this.mediaSelectionStep.textFileUploadSelected;
            // this.mediaSelectionStep.complete = false;
            // this.manualTextInputSelected = false;
            // this.manualTextInputSelected = true; // uncomment to set this as default value on page load
            // this.textFileUploadSelected = false;
        }
        // let textAdded = [
        //     "Hello", "My name is Scott", "Adventure Time", "Brady", "Treehouse", "Exploration", 
        //     "Wanderlust", "The sun sets over the horizon", "Coding is fun", "Imagination", 
        //     "Books are windows to the world", "Create", "Technology", "Innovation", 
        //     "The cat sat on the mat", "Learning new things", "Chase your dreams", 
        //     "The quick brown fox jumps over the lazy dog", "Music", "Art", "Mountains", 
        //     "Travel far and wide", "Ocean waves", "Knowledge is power", "Skyline", 
        //     "City lights", "The future is bright", "Eclipse", "Daydream", 
        //     "Happiness", "Journey through the stars", "Breathe", "Explore the unknown", 
        //     "Adventure awaits", "Serenity", "Peaceful moments", "Sunrise", "The world is vast", 
        //     "Write your story", "Discovery", "Gaze at the stars", "Listen to the wind", 
        //     "Inspire others", "Laughter", "Moonlight", "Whisper", "Innovation drives progress", 
        //     "The road less traveled", "Glimpse of the future", "Horizon", "Build something great", 
        //     "Courage", "Believe in yourself", "Dream big", "A new chapter begins", "The sky's the limit", 
        //     "Wisdom", "Friendship", "Sailing the seas", "Imagination knows no bounds", 
        //     "Gratitude", "Change the world", "Nature", "Whispers of the forest", 
        //     "Roam free", "Candlelight", "Universe", "Stargazing", "Seasons change", 
        //     "Overcome obstacles", "Listen to your heart", "Pioneers", "Connect", 
        //     "Create a masterpiece", "Rivers flow", "Take a leap of faith", "Courage to change", 
        //     "Innovation shapes tomorrow", "Never stop exploring", "Life is a journey", 
        //     "The power of positivity", "Sunshine and rainbows", "Embrace the unknown", 
        //     "Find your passion", "Chase the sun", "Grow", "Seek adventure", "Share your story", 
        //     "Embrace change", "Find your voice", "The world is your canvas", 
        //     "Write your own ending", "Trust the process", "Be the change", "Overcome", 
        //     "Dare to dream", "Explore new horizons", "Celebrate life", "Dreamers", "Fly high"
        // ];
        // this.textPost.textAdded = textAdded;

        // if(this.isDraftPost){
        //     this.checkForMediaSelectionDifferences();
        // }
    }
    
}
</script>
<style scoped>
.text-added-container {
    max-height: 25vh;
    /* max-width: 100%; */
    overflow-y: scroll;
    overflow-x: hidden;
}

.text-added-container::-webkit-scrollbar {
    width: 7px;
    height: 100%;
}

/* Handle */
.text-added-container::-webkit-scrollbar-thumb {
    background: #86c232 !important;
    height: 20% !important;
}
.v-tooltip__content {
    background-color: #424242 !important;
}


@keyframes slideDown {
    from {
        opacity: 0;
        transform: translateY(-50px);
        z-index: 0;
    }
    to {
        opacity: 1;
        z-index: 1;
    }
}
.slideDown {
    animation-name: slideDown;
    animation-duration: .4s;
}



@keyframes slideRight {
    from {
        opacity: 0;
        transform: translateX(-50px);
        z-index: 3;
    }
    to {
        opacity: 1;
        z-index: 0;
    }
}
.slideRight {
    animation-name: slideRight;
    animation-duration: .4s;
}


    
</style>